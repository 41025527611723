@import "./../theme-bootstrap";

.product-full {
  max-width: $max-width;
  margin: 0 auto;
  padding: 8px 0 0 0;
  position: relative;
  @include breakpoint($medium-up) {
    @include pie-clearfix;
    padding: 15px 0 25px 0;
  }
  &__extole {
    text-transform: uppercase;
  }
}

.product-full__breadcrumb {
  display: none;
  @include breakpoint($medium-up) {
    display: block;
  }
}

.product-full__header {
  padding: 0 10px;
  text-align: center;
  @include breakpoint($medium-up) {
    float: $rdirection;
    width: 48%;
    padding: 0;
    text-align: $ldirection;
  }
}

.product-full__subline {
  @include t2;
  font-size: 12px;
  margin: 0 0 2px 0;
  font-family: $font--avenir-light;
  text-transform: uppercase;
  @include breakpoint($medium-up) {
    font-size: 14px;
  }
}

.product-full__name {
  @include header--small;
  font-family: $font--avenir-book;
  width: 90%;
  margin: 0 auto;
  @include breakpoint($medium-up) {
    @include header--large;
    font-family: $font--avenir-book;
    margin-#{$ldirection}: -2px;
    margin-bottom: 2px;
  }
}

.product-full__short-description {
  font-size: 19px;
  font-family: $font--avenir-light;
}

.product-full__details {
  position: relative;
  background: $color-bg;
  padding: 0 10px 20px 10px;
  @include breakpoint($medium-up) {
    background: none;
    margin-top: 0;
    float: $rdirection;
    width: 48%;
    margin-bottom: 30px;
    padding-#{$rdirection}: 20px;
    overflow: hidden;
    clear: $rdirection;
  }
  .selectBox {
    background: $color-white;
    &-label {
      color: $color-black;
    }
    @include breakpoint($medium-down) {
      width: 100%;
    }
  }
  .product-replenishment-select {
    font-size: 16px;
    padding-bottom: 10px;
    @include breakpoint($medium-up) {
      padding-bottom: 15px;
    }
  }
  .product-sku-price {
    margin: 0px 0 7px 0;
    font-size: 19px;
    line-height: 19px;
    &__value {
      display: block;
    }
  }
}

@include breakpoint($small-down) {
  .product-full__details {
    .product-sku-price {
      font-size: 16px;
      line-height: 16px;
    }
  }
}

.product-full__add-to-bag {
  margin-top: -8px;
  @include breakpoint($medium-up) {
    margin: 0 0 12px 0;
  }
  .button {
    width: 100%;
    font-size: 19px;
    @include button-large--desktop;
  }
  .product__button--shop-wholesale {
    margin-bottom: 14px;
    @include breakpoint($medium-up) {
      margin-bottom: 10px;
    }
  }
}

.product-full__options {
  margin-top: 15px;
  .sku-menu {
    &__single {
      font-size: 16px;
      @include breakpoint($medium-up) {
        font-size: 19px;
        line-height: 45px;
        margin-#{$rdirection}: 20px;
      }
    }
  }
  .sku-menu__container,
  .quantity-menu__container {
  }
  .product-select {
    margin: 1em 0;
    @include breakpoint($portrait-up) {
      float: $ldirection;
      margin-#{$rdirection}: 20px;
    }
  }
}

.product-full__badge {
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;
  height: 63px;
  z-index: 1;
  #{$ldirection}: 10px;
  @include breakpoint($medium-up) {
    width: 158px;
    height: 101px;
    #{$ldirection}: 35px;
    top: 10px;
  }
  &--award {
    background-image: url('/media/images/products/badges/badge_award.png');
  }
  &--exclusive {
    background-image: url('/media/images/products/badges/badge_exclusive.png');
  }
  &--limitededition {
    background-image: url('/media/images/products/badges/badge_limitededition.png');
  }
  &--new {
    background-image: url('/media/images/products/badges/badge_new.png');
  }
  &--stylistpick {
    background-image: url('/media/images/products/badges/badge_stylistpick.png');
  }
  &--topseller {
    background-image: url('/media/images/products/badges/badge_topseller.png');
  }
}

.product-full__images {
  background: $color-bg;
  position: relative;
  text-align: center;
  @include breakpoint($medium-up) {
    float: $ldirection;
    width: 50%;
    overflow: hidden;
    background: none;
  }
}

.product-full__image {
  .slick-dots {
    position: relative;
    top: -30px;
    margin: 0;
  }
}

.product-full__image-slide {
  position: relative;
  margin-bottom: 14px;
  &.slick-slide {
    margin-bottom: 0;
    img {
      margin: 0 auto;
    }
  }
}

.product-full__video {
  cursor: pointer;
}

.product-full__video-image {
  position: relative;
  .icon {
    @include vertical-horizontal-center;
    transition: transform $transition-duration $transition-effect;
    backface-visibility: hidden;
    position: absolute;
    font-size: 60px;
    z-index: 1;
    color: $color-white;
    text-shadow: 1px 1px 1px $color-black;
    @include breakpoint($medium-up) {
      font-size: 70px;
    }
    @include breakpoint($medium-up) {
      @include transform(none);
      top: auto;
      bottom: 30px;
      #{$ldirection}: 20px;
    }
  }
  &:hover {
    .no-touchevents & {
      .icon {
        @include transform(scale(1.2));
      }
    }
  }
}

.product-full__ingredients-label {
  cursor: pointer;
}

.product-full__refer-extole {
  @include swap_direction(margin, 22px 0 10px 0);
  text-transform: uppercase;
  cursor: pointer;
}

.product-full__add-favorites {
  position: absolute;
  top: 10px;
  #{$rdirection}: 10px;
  z-index: 10;
  @include breakpoint($medium-up) {
    position: static;
    display: inline-block;
  }
  .product__button--add-favorites {
    font-size: 30px;
    &:hover {
      color: $color-link-hover;
    }
    @include breakpoint($medium-up) {
      margin-#{$rdirection}: 15px;
    }
  }
}

.product-full__social {
  margin-top: 10px;
  padding-top: 18px;
  border-top: 1px solid $color-border;
  @include breakpoint($medium-up) {
    margin-top: 0;
    padding-top: 0;
    border: 0;
    display: inline-block;
  }
}

.product-full__products {
  font-size: 0;
  @include breakpoint($medium-up) {
    margin: 0 0 28px 0;
    &:empty {
      margin-bottom: 0;
    }
  }
  .button {
    margin-#{$rdirection}: 10px;
    margin-top: 5px;
    @include breakpoint($medium-up) {
      margin-top: 10px;
    }
  }
}

.product-full__detail {
  border-top: 1px solid $color-border;
  padding-top: 18px;
  margin-top: 10px;
  @include breakpoint($medium-up) {
    font-size: 14px;
    padding-top: 18px;
    margin-top: 18px;
  }
  h2 {
    text-transform: uppercase;
    font-size: 19px;
  }
  h5 {
    text-transform: uppercase;
    margin-top: 18px;
    font-size: 14px;
  }
  .product-benefits__list {
    @include breakpoint($medium-down) {
      padding-bottom: 10px;
    }
  }
}

.product-full__detail-title {
  cursor: pointer;
  margin: 0;
  padding: 0 0 5px 0;
  @include breakpoint($medium-up) {
    cursor: auto;
    padding-bottom: 7px;
  }
  .icon {
    float: $rdirection;
    @include breakpoint($medium-up) {
      display: none;
      .product-full__ingredients & {
        display: block;
      }
    }
  }
}

.product-full__detail-content {
  display: none;
  margin-bottom: 10px;
  @include breakpoint($medium-up) {
    display: block;
    .product-full__ingredients & {
      display: none;
    }
  }
  &--important {
    font-family: 'avenir-heavy';
  }
  &--open {
    display: block;
  }
}

.product-full__cruelty-free-logo {
  width: 50px;
}

// power-reviews overrides
.product-full__header,
.product-full__details {
  .pr-snippet-stars-container {
    display: block !important;
    @include breakpoint($medium-up) {
      display: inline-block !important;
    }
  }
  .p-w-r .pr-snippet .pr-snippet-stars-png .pr-rating-stars {
    vertical-align: top;
  }
  .p-w-r .pr-snippet-stars-reco-inline .pr-snippet-stars-reco-stars {
    float: none !important;
    @include breakpoint($medium-up) {
      float: $ldirection !important;
    }
  }
}

.product__inventory-status {
  &-item {
    @include swap_direction(margin, 6px 0);
    font-size: 14px;
    color: $color-red;
    text-align: center;
  }
  &-container {
    font-weight: bold;
    ul.product__inventory-status {
      li {
        margin: 0;
        &.temp-out-of-stock {
          margin: 0 5px 10px;
        }
      }
    }
    @include breakpoint($medium-up) {
      width: 260px;
      display: flex;
      flex: 1 0 auto;
      margin: 0 auto;
      justify-content: center;
      text-align: center;
    }
  }
}

// @TODO - should have more specificity and moved inside _tooltipster.scss
.tooltipster-container {
  background: $color-black;
  color: $color-white;
  max-width: 300px;
  a {
    color: $color-white;
    text-decoration: underline;
  }
  .tooltipster-content {
    font-size: 14px;
    line-height: normal;
    overflow: hidden;
    padding: 15px;
    text-transform: uppercase;
  }
}
